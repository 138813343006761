<template>
  <div class="assign-account-dropdown">
    <v-menu
      bottom
      offset-y
      v-model="isOpen"
      :close-on-content-click="false"
      :close-on-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on">
          <v-icon class="mr-2">{{$icon('i.Link')}}</v-icon>
          <span>{{$t('t.AssignToAccount')}}</span>
        </v-btn>
      </template>

      <v-card class="pa-4">
        <div class="d-flex flex-column ">
          <cot-form
            ref="form"
            v-model="formValid"
            class="assign-account mt-6"
          >
            <picker
              document-types="accounts"
              :attach="false"
              :rules="required"
              :label="$t('t.SelectAccount')"
              :selected-id.sync="accountId"
              :scope="assignAccountScope"
              :placeholder="placeholder"
              :show-detail="true"
              ref="picker-account"
            >
              <template
                v-slot:append
                v-if="from.userId && from.isAccountContact"
              >
                <toggle-link-unlink
                  :scope.sync="scope"
                  :tooltip-link="$t('t.AccountsLinkedToContact')"
                  :tooltip-unlink="$t('t.AllAccounts')"
                />
              </template>
            </picker>
            <user-ref
              class="mt-6"
              :id="from.userId"
              :fullname="contact.fullname"
              :email="contact.email"
              z-index="9999"
            />
            <div
              class="mt-6"
              v-if="!from.userId || showContactGroup"
            >
              <v-switch
                v-model="addContact"
                :label="labelContact"
              />
              <div v-if="addContact">
                <picker
                  dense
                  document-types="contact-groups"
                  class="mt-6"
                  :selected-id.sync="contact.contactGroupId"
                  :clearable="false"
                  :rules="required"
                  :label="$t('t.ContactGroup')"
                  :attach="false"
                  hide-message
                  ref="picker-group"
                />
                <div class="d-flex mt-4">
                  <v-text-field
                    dense
                    style="width: 10%;"
                    :label="$t('t.Title')"
                    v-model="contact.title"
                  />
                  <v-text-field
                    dense
                    class="ml-2"
                    :label="$t('t.Name')"
                    v-model="contact.fullname"
                    :rules="required"
                  />
                </div>
                <v-text-field
                  dense
                  type="email"
                  :prepend-inner-icon="$icon('i.Email')"
                  :label="$t('t.Email')"
                  v-model="contact.email"
                  readonly
                />
                <div class="d-flex">
                  <v-select
                    dense
                    :items="messagingCultures"
                    item-text="name"
                    item-value="code"
                    :label="$t('t.UserCulture')"
                    v-model="contact.culture"
                  />
                  <v-checkbox
                    dense
                    :label="$t('t.PortalAccess')"
                    v-model="contact.isAllowPortalAccess"
                    class="ml-2"
                  />
                </div>
                <div class="d-flex">
                  <v-text-field
                    dense
                    :label="$t('t.JobTitle')"
                    v-model="contact.jobTitle"
                  />
                  <v-text-field
                    dense
                    class="ml-2"
                    :label="$t('t.AvailabilityHours')"
                    v-model="contact.availabilityHours"
                  />
                </div>
                <div class="d-flex">
                  <v-text-field
                    dense
                    :label="$t('t.Phone')"
                    v-model="contact.phone1"
                  >
                    <template v-slot:prepend-inner>
                      <phone-btn
                        :phone-number="contact.phone1"
                        :show-phone-number="false"
                        normal
                      />
                    </template>
                  </v-text-field>
                  <v-text-field
                    dense
                    class="ml-2"
                    :label="$t('t.Phone2')"
                    v-model="contact.phone2"
                  >
                    <template v-slot:prepend-inner>
                      <phone-btn
                        :phone-number="contact.phone2"
                        :show-phone-number="false"
                        normal
                      />
                    </template>
                  </v-text-field>
                </div>
              </div>
            </div>
          </cot-form>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            fab
            small
            color="success"
            @click.stop="close(true)"
            :disabled="!formValid"
          >
            <v-icon ref="check">{{$icon('i.Checked')}}</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            color="warning"
            @click.stop="close()"
          >
            <v-icon ref="check">{{$icon('i.Undo')}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Scope from '@/pages/search/controllers/scope'

export default {
  components: {
    CotForm: () => import('@/components/cot-form'),
    Picker: () => import('@/components/picker'),
    ToggleLinkUnlink: () => import('@/components/toggle-link-unlink'),
    UserRef: () => import('@/components/documents-ref/user-ref'),
    PhoneBtn: () => import('@/components/phone-btn')
  },
  async mounted () {
    this.loadCultures()
  },
  data () {
    return {
      addContact: true,
      formValid: false,
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      isOpen: false,
      accountId: null,
      contact: {
        fullname: null,
        email: null,
        contactGroupId: null,
        isAccountContact: true,
        isActive: true,
        isAllowPortalAccess: false,
        culture: undefined,
        phone1: undefined,
        phone2: undefined,
        title: undefined,
        jobTitle: undefined,
        availabilityHours: undefined
      },
      defaultCulture: null,
      messagingCultures: [],
      scope: 'local',
      showContactGroup: false
    }
  },
  computed: {
    assignAccountScope () {
      return this.from?.userId && this.scope === 'local' ? Scope.user(this.from?.userId) : undefined
    },
    placeholder () {
      return this.scope === 'local' ? this.$t('t.AccountsLinkedToContact') : this.$t('t.AllAccounts')
    },
    labelContact () {
      return this.from?.userId ? this.$t('t.LinkContact') : this.$t('t.AddContact')
    }
  },
  methods: {
    close (apply) {
      if (apply) {
        this.$emit('apply',
          {
            accountId: this.accountId,
            // We need to create a shallow clone of this.contact to prevent side effects on the emited object
            contact: this.addContact ? JSON.parse(JSON.stringify(this.contact)) : null
          }
        )
      } else {
        this.showContactGroup = false
        this.contact = {
          availabilityHours: this.from.availabilityHours,
          contactGroupId: null,
          culture: this.from.culture ?? this.defaultCulture,
          email: this.from.email ?? null,
          fullname: this.from.fullname ?? null,
          isAccountContact: this.from.isAccountContact ?? true,
          isActive: true,
          isAllowPortalAccess: this.from.isAllowPortalAccess ?? false,
          jobTitle: this.from.jobTitle,
          phone1: this.from.phone1,
          phone2: this.from.phone2,
          title: this.from.title
        }
        this.scope = 'user'
        this.$refs['picker-account'].resetAutocomplete()
        this.$refs['picker-group']?.resetAutocomplete()
        this.isOpen = false
      }
    },
    async contactIsOnAccount () {
      if (this.from?.userId) {
        const result = await this.$http().get(`/core/v6/message/${this.accountId}/${this.from.userId}`)
        if (result?.data?.contactGroupId) {
          this.contact.contactGroupId = result.data.contactGroupId
        }
        return !!result.data.contactGroupId
      }
      return false
    },
    async loadCultures () {
      const r = await this.$http().get('/core/v6/settings/cultures/messaging')
      let { cultures, defaultCulture } = r.data

      cultures = cultures.map(c => {
        return {
          name: this.$t(`t.${c}`),
          code: c
        }
      })

      this.messagingCultures = cultures
      this.defaultCulture = defaultCulture
    }
  },
  watch: {
    from: {
      immediate: true,
      handler (n, o) {
        if (!n) { return }
        this.contact = {
          availabilityHours: n.availabilityHours,
          contactGroupId: null,
          culture: n.culture ?? this.defaultCulture,
          email: n.email ?? null,
          fullname: n.fullname ?? null,
          isAccountContact: n.isAccountContact ?? true,
          isActive: true,
          isAllowPortalAccess: n.isAllowPortalAccess ?? false,
          jobTitle: n.jobTitle,
          phone1: n.phone1,
          phone2: n.phone2,
          title: n.title
        }
        this.scope = n.userId && n.isAccountContact ? 'local' : 'global'
      }
    },
    async accountId (v) {
      if (v) {
        this.showContactGroup = !(await this.contactIsOnAccount()) && this.contact.isAccountContact
      }
    },
    isOpen (v) {
      if (v) {
        this.$parent.loadMessage()
        this.$refs.form?.validate()
      }
    }
  },
  props: {
    from: Object
  }
}
</script>

<style lang="stylus" scoped></style>
